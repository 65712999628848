import React from "react";

function Icon() {
    return (
        <svg
            height="100"
            viewBox="0 0 83 113"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.7"
                d="M26.7802 87.1546C26.7802 87.1546 22.4974 89.6469 29.8322 90.4878C38.7196 91.503 43.2612 91.3575 53.0524 89.5067C53.0524 89.5067 55.6318 91.1194 59.2273 92.5174C37.2704 101.924 9.53654 91.972 26.7802 87.1546ZM24.0957 74.8774C24.0957 74.8774 19.2909 78.4344 26.632 79.1944C36.1258 80.1746 43.6215 80.2546 56.5985 77.7569C56.5985 77.7569 58.3891 79.5763 61.2093 80.5699C34.6676 88.3324 5.10545 81.1809 24.0957 74.8774V74.8774Z"
                fill="#222226"
            />
            <path
                opacity="0.7"
                d="M46.7125 54.0484C52.1256 60.279 45.2929 65.8808 45.2929 65.8808C45.2929 65.8808 59.0292 58.7912 52.7221 49.9093C46.8293 41.6302 42.3119 37.518 66.7692 23.3335C66.7692 23.3344 28.3781 32.9198 46.7125 54.0484V54.0484Z"
                fill="#222226"
            />
            <path
                opacity="0.7"
                d="M75.7514 96.237C75.7514 96.237 78.9219 98.8515 72.2582 100.872C59.5885 104.71 19.5146 105.867 8.38471 101.026C4.38577 99.2845 11.8877 96.8704 14.2479 96.361C16.7087 95.8282 18.1139 95.9253 18.1139 95.9253C13.6639 92.7924 -10.6478 102.08 5.76667 104.743C50.5277 111.999 87.3601 101.476 75.7514 96.237V96.237ZM28.843 62.1548C28.843 62.1548 8.46108 66.9965 21.6259 68.7565C27.1854 69.5004 38.2632 69.3297 48.5889 68.4636C57.0252 67.7548 65.4912 66.2409 65.4912 66.2409C65.4912 66.2409 62.5192 67.5158 60.3665 68.9838C39.6647 74.4292 -0.317552 71.893 11.1941 66.3262C20.926 61.6193 28.843 62.1548 28.843 62.1548V62.1548ZM65.4067 82.5916C86.4491 71.6603 76.719 61.1548 69.9277 62.5699C68.2674 62.9158 67.5226 63.2167 67.5226 63.2167C67.5226 63.2167 68.1407 62.2473 69.3194 61.8305C82.7538 57.1092 93.084 75.7589 64.9863 83.1459C64.9863 83.1441 65.3088 82.8521 65.4067 82.5916V82.5916Z"
                fill="#222226"
            />
            <path
                opacity="0.7"
                d="M52.7217 0.425781C52.7217 0.425781 64.3726 12.0839 41.6682 30.005C23.4605 44.3854 37.5165 52.5828 41.6619 61.9526C31.0325 52.3636 23.2358 43.9209 28.4657 36.0641C36.1455 24.5336 57.4214 18.9408 52.7217 0.425781V0.425781Z"
                fill="#222226"
            />
            <path
                opacity="0.7"
                d="M30.9114 112.222C51.1046 113.513 82.1223 111.503 82.8563 101.947C82.8563 101.947 81.4449 105.57 66.1661 108.444C48.9286 111.689 27.6644 111.311 15.0566 109.229C15.0566 109.23 17.6396 111.368 30.9114 112.222V112.222Z"
                fill="#222226"
            />
        </svg>
    );
}

export default Icon;
