import InfoBlockProps from "./InfoBlockProps";
import React, { FunctionComponent } from "react";
import "./InfoBlock.scss";

const InfoBlock: FunctionComponent<InfoBlockProps> = ({
  headerText,
  bodyText,
  children,
}) => (
  <div className="infoblock">
    <div className="infoblock-header block-header-text">{headerText}</div>
    <div className="infoblock-body block-body-text">{bodyText}</div>
    <div className="infoblock-children row justify-content-between">
      {children}
    </div>
  </div>
);

export default InfoBlock;
