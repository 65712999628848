import React from "react";
import IconProps from "./IconProps";

function Icon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39 15V21"
        stroke="#021E74"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 19V29.8456C27.1372 32.4508 23.409 33.9287 19.5196 34C15.6198 33.9226 11.8813 32.4462 9 29.8456V19"
        stroke="#E90057"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 14.5L20 23L39 14.5L20 6L1 14.5Z"
        stroke="#021E74"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
