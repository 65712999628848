import React, { Component } from "react";
import CardDescriptionProps from "./CardDescriptionProps";
import "./CardDescription.scss";

export class CardDescription extends Component<
    CardDescriptionProps,
    {
        cardDecription: string | undefined;
        activeCardIndex: number;
    }
> {
    constructor(props: CardDescriptionProps) {
        super(props);

        this.state = {
            cardDecription: props.cards ? props.cards[0].bodyText : "",
            activeCardIndex: 0,
        };
    }

    updateCardDescription = (key: number) => {
        this.setState({
            cardDecription: this.props.cards[key].bodyText,
            activeCardIndex: key,
        });
    };

    render() {
        return (
            <>
                {this.props.cards &&
                    this.props.cards.map((card, index) => {
                        return (
                            <button
                                key={index}
                                className={
                                    "custom-card" +
                                    (this.state.activeCardIndex === index
                                        ? " active"
                                        : "")
                                }
                                onClick={() =>
                                    this.updateCardDescription(index)
                                }
                            >
                                <div>
                                    {card.icon && (
                                        <div className="custom-card-pic d-flex justify-content-center">
                                            {card.icon}
                                        </div>
                                    )}
                                    {card.headerText && (
                                        <div className="custom-card-text">
                                            {card.headerText}
                                        </div>
                                    )}
                                </div>
                            </button>
                        );
                    })}
                <div
                    className={
                        "block-body-text custom-card-desc" +
                        (this.props.cards ? " show" : "")
                    }
                >
                    {this.state.cardDecription}
                </div>
            </>
        );
    }
}

export default CardDescription;
