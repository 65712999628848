import React from "react";

function Icon() {
  return (
    <svg
      height="100"
      viewBox="0 0 104 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M51.3912 0.987978C47.1692 1.00798 43.1392 1.36698 39.5912 1.99498C29.1412 3.84098 27.2452 7.70498 27.2452 14.832V24.243H51.9382V27.38H17.9772C10.8012 27.38 4.5172 31.693 2.5512 39.901C0.283202 49.306 0.183202 55.176 2.5512 64.997C4.3062 72.308 8.4982 77.516 15.6752 77.516H24.1662V66.234C24.1662 58.083 31.2172 50.894 39.5922 50.894H64.2572C71.1232 50.894 76.6032 45.24 76.6032 38.346V14.833C76.6032 8.13998 70.9572 3.11298 64.2572 1.99598C60.0132 1.28998 55.6122 0.968978 51.3912 0.987978V0.987978ZM38.0372 8.55698C40.5872 8.55698 42.6712 10.674 42.6712 13.278C42.6712 15.871 40.5882 17.968 38.0372 17.968C35.4772 17.968 33.4042 15.871 33.4042 13.278C33.4032 10.674 35.4772 8.55698 38.0372 8.55698V8.55698Z"
        fill="#222226"
      />
      <path
        opacity="0.7"
        d="M79.6831 27.38V38.346C79.6831 46.846 72.4751 54.001 64.2571 54.001H39.5921C32.8361 54.001 27.2461 59.784 27.2461 66.55V90.065C27.2461 96.756 33.0641 100.693 39.5921 102.612C47.4081 104.909 54.9041 105.325 64.2571 102.612C70.4731 100.811 76.6031 97.189 76.6031 90.065V80.653H51.9391V77.515H88.9511C96.1271 77.515 98.8031 72.51 101.299 64.996C103.877 57.261 103.766 49.822 101.299 39.9C99.5251 32.755 96.1381 27.379 88.9511 27.379L79.6831 27.38ZM65.8101 86.927C68.3711 86.927 70.4441 89.024 70.4441 91.619C70.4441 94.221 68.3701 96.338 65.8101 96.338C63.2601 96.338 61.1771 94.221 61.1771 91.619C61.1771 89.024 63.2601 86.927 65.8101 86.927Z"
        fill="#222226"
      />
    </svg>
  );
}

export default Icon;
