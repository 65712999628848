import React from "react";
import IconProps from "./IconProps";

function Icon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 2H31V39H9V2Z"
        stroke="#021E74"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 2V5.75C15 6.08152 15.1317 6.39946 15.3661 6.63388C15.6005 6.8683 15.9185 7 16.25 7H23.75C24.0815 7 24.3995 6.8683 24.6339 6.63388C24.8683 6.39946 25 6.08152 25 5.75V2"
        stroke="#021E74"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2132 17H18.8628C18.1977 17.0004 17.5534 17.2177 17.0406 17.6145C16.5278 18.0113 16.1783 18.5629 16.0521 19.1747C15.9258 19.7865 16.0308 20.4204 16.3489 20.9676C16.667 21.5149 17.1784 21.9414 17.7954 22.174L22.1993 23.824C22.8177 24.0558 23.3306 24.4822 23.6497 25.0299C23.9689 25.5776 24.0743 26.2124 23.948 26.8251C23.8216 27.4378 23.4714 27.9901 22.9574 28.3871C22.4435 28.784 21.798 29.0007 21.1319 29H16.8092"
        stroke="#E90057"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 16V15"
        stroke="#E90057"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 31V30"
        stroke="#E90057"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
