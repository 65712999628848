import React from "react";

function Icon() {
  return (
    <svg height="40" viewBox="0 0 393 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M124.97 41.333C126.34 46.2836 130.053 48.7589 136.109 48.7589C140.007 48.7589 142.956 47.4423 144.957 44.8089L153.173 49.549C149.276 55.1843 143.535 58.002 135.951 58.002C129.421 58.002 124.18 56.027 120.23 52.077C116.28 48.1269 114.305 43.1499 114.305 37.146C114.305 31.1946 116.254 26.2439 120.151 22.2939C124.049 18.2913 129.052 16.29 135.161 16.29C140.955 16.29 145.721 18.2913 149.46 22.2939C153.252 26.2966 155.148 31.2473 155.148 37.146C155.148 38.4626 155.017 39.8583 154.753 41.333H124.97ZM124.812 33.4329H144.957C144.378 30.7469 143.167 28.7456 141.323 27.4289C139.533 26.1123 137.479 25.4539 135.161 25.4539C132.423 25.4539 130.158 26.1649 128.367 27.5869C126.577 28.9563 125.392 30.9049 124.812 33.4329Z" fill="#202025"/>
      <path d="M174.071 28.6139C174.071 29.6673 174.755 30.5363 176.125 31.2209C177.547 31.8529 179.258 32.4323 181.26 32.959C183.261 33.433 185.262 34.0649 187.264 34.8549C189.265 35.5923 190.95 36.8563 192.32 38.6469C193.742 40.4376 194.453 42.6759 194.453 45.3619C194.453 49.4173 192.925 52.5509 189.871 54.7629C186.869 56.9223 183.103 58.002 178.574 58.002C170.463 58.002 164.933 54.8683 161.984 48.601L170.832 43.624C171.99 47.0473 174.571 48.7589 178.574 48.7589C182.208 48.7589 184.025 47.6266 184.025 45.3619C184.025 44.3086 183.314 43.466 181.892 42.834C180.522 42.1493 178.837 41.5436 176.836 41.0169C174.834 40.4903 172.833 39.832 170.832 39.042C168.83 38.2519 167.119 37.0143 165.697 35.3289C164.327 33.5909 163.643 31.4316 163.643 28.8509C163.643 24.9536 165.065 21.899 167.909 19.687C170.805 17.4223 174.387 16.29 178.653 16.29C181.865 16.29 184.788 17.0273 187.422 18.5019C190.055 19.924 192.135 21.9779 193.663 24.6639L184.973 29.4039C183.709 26.7179 181.602 25.375 178.653 25.375C177.336 25.375 176.23 25.6646 175.335 26.2439C174.492 26.8233 174.071 27.6133 174.071 28.6139Z" fill="#202025"/>
      <path d="M223.219 58.002C217.267 58.002 212.29 56.0006 208.288 51.9979C204.338 47.9953 202.363 43.0446 202.363 37.146C202.363 31.2473 204.338 26.2966 208.288 22.2939C212.29 18.2913 217.267 16.29 223.219 16.29C227.063 16.29 230.566 17.2116 233.726 19.0549C236.886 20.8983 239.282 23.3736 240.915 26.4809L232.146 31.6159C231.356 29.9833 230.144 28.6929 228.512 27.7449C226.932 26.7969 225.141 26.3229 223.14 26.3229C220.085 26.3229 217.557 27.3499 215.556 29.4039C213.554 31.4053 212.554 33.986 212.554 37.146C212.554 40.2533 213.554 42.834 215.556 44.888C217.557 46.8893 220.085 47.8899 223.14 47.8899C225.194 47.8899 227.011 47.4423 228.591 46.547C230.223 45.599 231.435 44.3086 232.225 42.6759L241.073 47.7319C239.335 50.8393 236.886 53.341 233.726 55.237C230.566 57.0803 227.063 58.002 223.219 58.002Z" fill="#202025"/>
      <path d="M283.449 51.9979C279.394 56.0006 274.443 58.002 268.597 58.002C262.751 58.002 257.801 56.0006 253.745 51.9979C249.743 47.9426 247.741 42.992 247.741 37.146C247.741 31.2999 249.743 26.3756 253.745 22.373C257.801 18.3176 262.751 16.29 268.597 16.29C274.443 16.29 279.394 18.3176 283.449 22.373C287.505 26.3756 289.532 31.2999 289.532 37.146C289.532 42.992 287.505 47.9426 283.449 51.9979ZM260.934 44.9669C262.988 47.021 265.543 48.048 268.597 48.048C271.652 48.048 274.206 47.021 276.26 44.9669C278.314 42.9129 279.341 40.306 279.341 37.146C279.341 33.986 278.314 31.379 276.26 29.325C274.206 27.271 271.652 26.2439 268.597 26.2439C265.543 26.2439 262.988 27.271 260.934 29.325C258.933 31.379 257.932 33.986 257.932 37.146C257.932 40.306 258.933 42.9129 260.934 44.9669Z" fill="#202025"/>
      <path d="M330.593 1.59595H340.784V56.896H330.593V52.2349C327.591 56.0796 323.325 58.002 317.795 58.002C312.475 58.002 307.92 56.0006 304.128 51.9979C300.388 47.9426 298.519 42.992 298.519 37.146C298.519 31.2999 300.388 26.3756 304.128 22.373C307.92 18.3176 312.475 16.29 317.795 16.29C323.325 16.29 327.591 18.2123 330.593 22.0569V1.59595ZM311.791 45.2039C313.897 47.2579 316.531 48.285 319.691 48.285C322.851 48.285 325.458 47.2579 327.512 45.2039C329.566 43.0973 330.593 40.4113 330.593 37.146C330.593 33.8806 329.566 31.221 327.512 29.167C325.458 27.0603 322.851 26.0069 319.691 26.0069C316.531 26.0069 313.897 27.0603 311.791 29.167C309.737 31.221 308.71 33.8806 308.71 37.146C308.71 40.4113 309.737 43.0973 311.791 45.2039Z" fill="#202025"/>
      <path d="M362.665 41.333C364.034 46.2836 367.747 48.7589 373.804 48.7589C377.701 48.7589 380.65 47.4423 382.652 44.8089L390.868 49.549C386.97 55.1843 381.23 58.002 373.646 58.002C367.115 58.002 361.875 56.027 357.925 52.077C353.975 48.1269 352 43.1499 352 37.146C352 31.1946 353.948 26.2439 357.846 22.2939C361.743 18.2913 366.746 16.29 372.856 16.29C378.649 16.29 383.415 18.2913 387.155 22.2939C390.947 26.2966 392.843 31.2473 392.843 37.146C392.843 38.4626 392.711 39.8583 392.448 41.333H362.665ZM362.507 33.4329H382.652C382.072 30.7469 380.861 28.7456 379.018 27.4289C377.227 26.1123 375.173 25.4539 372.856 25.4539C370.117 25.4539 367.852 26.1649 366.062 27.5869C364.271 28.9563 363.086 30.9049 362.507 33.4329Z" fill="#202025"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.82843 32.5269L24.7487 54.4472L37.1231 42.0729L24.7487 29.6985L37.1231 17.3241L24.7487 4.94975L2.82843 26.8701C1.26633 28.4322 1.26633 30.9648 2.82843 32.5269ZM13.435 33.234L35.3553 55.1543L47.7297 42.78L35.3553 30.4056L47.7297 18.0312L35.3553 5.65686L13.435 27.5772C11.8729 29.1393 11.8729 31.6719 13.435 33.234Z" fill="#021E74"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M86.5685 40.4731L64.6482 18.5528L52.2739 30.9271L64.6482 43.3015L52.2739 55.6759L64.6482 68.0503L86.5685 46.1299C88.1306 44.5678 88.1306 42.0352 86.5685 40.4731ZM75.9619 39.766L54.0416 17.8457L41.6673 30.22L54.0416 42.5944L41.6673 54.9688L54.0416 67.3431L75.9619 45.4228C77.524 43.8607 77.524 41.3281 75.9619 39.766Z" fill="#E90057"/>
    </svg>
  );
}

export default Icon;
