import React from "react";
import { withTranslation } from "react-i18next";
import {
  ProgressIcon,
  ServicesIcon,
  CartIcon,
  ClipboardIcon,
  DesktopGearIcon,
  EducationIcon,
  PlanetIcon,
  RocketIcon,
} from "../components/icons";
import {
  CardDescription,
  Carousel,
  Article,
  InfoBlock,
  MainBlock,
} from "../components";
import "./Page.scss";
import CardProps from "../components/card/CardProps";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  const cards: CardProps[] = [
    {
      icon: <CartIcon />,
      headerText: t("ECOMMERCE"),
      bodyText: t("ECOMMERCEDESCRIPTION"),
      isActive: true,
    },
    {
      icon: <ClipboardIcon />,
      headerText: t("FINTECH"),
      bodyText: t("FINTECHDESCRIPTION"),
      isActive: false,
    },
    {
      icon: <DesktopGearIcon />,
      headerText: t("SOFTWAREHITECH"),
      bodyText: t("SOFTWAREHITECHDESCRIPTION"),
      isActive: false,
    },
    {
      icon: <EducationIcon />,
      headerText: t("EDUCATION"),
      bodyText: t("EDUCATIONDESCRIPTION"),
      isActive: false,
    },
  ];

  return (
    <div>
      <div className="section row">
        <MainBlock />
      </div>
      <div className="companies section row align-items-center justify-content-between">
        <img alt="euipo" src="img/euipo.png" className="mb-5" />
        <img alt="citadele" src="img/citadele.png" className="mb-5" />
        <img alt="tele2" src="img/tele2.png" className="mb-5" />
      </div>
      <div className="section row">
        <InfoBlock
          headerText={t("OURSERVICES")}
          bodyText={t("OURSERVICESDESCRIPTION")}
        >
          <ServicesIcon />
        </InfoBlock>
      </div>
      <div className="section row">
        <InfoBlock
          headerText={t("WEWORKWITH")}
          bodyText={t("WEWORKWITHDESCRIPTION")}
        >
          <CardDescription cards={cards} />
        </InfoBlock>
      </div>
      <div className="section row">
        <InfoBlock
          headerText={t("TECHNOLOGIESWEWORKWITH")}
          bodyText={t("WEWORKWITHDESCRIPTION")}
        >
          <Carousel />
        </InfoBlock>
      </div>
      <div className="section row">
        <Article
          prefixText={t("ABOUTUS")}
          headerText={t("OURGOALS")}
          bodyText={t("OURGOALSDESCRIPTION")}
          image={<PlanetIcon />}
        />
      </div>
      <div className="section row">
        <ProgressIcon />
      </div>
      <div className="section row">
        <Article
          prefixText={t("PRINCIPLES")}
          headerText={t("PRINCIPLESHEADER")}
          bodyText={t("PRINCIPLESDESCRIPTION")}
          image={<RocketIcon />}
          rightVariant={true}
        />
      </div>
    </div>
  );
}

export default withTranslation()(About);
