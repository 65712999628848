import React from "react";

function Icon() {
    return (
        <svg
            height="100"
            viewBox="0 0 99 99"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.7"
                d="M89.3994 6.59987C88.0498 9.83695 86.3031 12.8937 84.1994 15.6999C79.6952 11.0402 74.3022 7.33138 68.3395 4.79273C62.3767 2.25408 55.9653 0.937233 49.4847 0.920091C43.004 0.90295 36.5857 2.18587 30.6096 4.69294C24.6335 7.20001 19.221 10.8803 14.6923 15.516C10.1636 20.1518 6.61068 25.6488 4.24387 31.6818C1.87705 37.7148 0.744377 44.1613 0.912866 50.6398C1.08135 57.1183 2.54758 63.4972 5.22479 69.399C7.902 75.3009 11.7358 80.6058 16.4994 84.9999L18.2994 86.5999C25.1301 92.3607 33.422 96.1185 42.2568 97.4572C51.0917 98.7959 60.1246 97.6632 68.3553 94.1846C76.5861 90.7059 83.6934 85.0171 88.8903 77.748C94.0871 70.479 97.1706 61.9134 97.7994 52.9999C99.1994 40.7999 95.4994 25.1999 89.3994 6.59987ZM23.4994 85.3999C22.9852 86.0317 22.2953 86.4969 21.5168 86.7368C20.7383 86.9766 19.9062 86.9803 19.1256 86.7474C18.345 86.5145 17.651 86.0554 17.1313 85.4281C16.6116 84.8009 16.2894 84.0336 16.2056 83.2234C16.1218 82.4131 16.28 81.5962 16.6604 80.8758C17.0407 80.1554 17.626 79.564 18.3424 79.1762C19.0588 78.7884 19.874 78.6217 20.6851 78.6971C21.4962 78.7725 22.2668 79.0867 22.8994 79.5999C23.7286 80.3023 24.2542 81.2982 24.366 82.3792C24.4778 83.4602 24.1673 84.5426 23.4994 85.3999V85.3999ZM89.0994 70.8999C77.1994 86.7999 51.5994 81.3999 35.2994 82.1999C35.2994 82.1999 32.3994 82.3999 29.4994 82.7999C29.4994 82.7999 30.5994 82.2999 31.9994 81.7999C43.4994 77.7999 48.8994 76.9999 55.8994 73.3999C68.9994 66.6999 82.0994 51.9999 84.6994 36.7999C79.6994 51.3999 64.4994 63.9999 50.6994 69.0999C41.1994 72.5999 24.0994 75.9999 24.0994 75.9999L23.3994 75.5999C11.7994 69.8999 11.3994 44.6999 32.5994 36.5999C41.8994 32.9999 50.6994 34.9999 60.7994 32.5999C71.4994 30.0999 83.8994 22.0999 88.8994 11.5999C94.4994 28.4999 101.299 54.6999 89.0994 70.8999Z"
                fill="#222226"
            />
        </svg>
    );
}

export default Icon;
