import { About } from "./pages";
import React from "react";
import { PageFooter, NavBar } from "./components";
import NavLinkProps from "./components/navlink/NavLinkProps";
import "./App.scss";

function App() {
  const pages: NavLinkProps[] = [{ path: "/", page: <About /> }];

  return (
    <>
      <NavBar navLinks={pages} />
      <PageFooter />
    </>
  );
}

export default App;
