import "./Carousel.scss";
import React from "react";
import {
    JavaScriptIcon,
    KotlinIcon,
    SpringIcon,
    DataScienceIcon,
    JavaIcon,
    PythonIcon,
    OracleIcon,
} from "../icons";

export default function Carousel() {
    const technologies: JSX.Element[] = [
        <JavaScriptIcon />,
        <PythonIcon />,
        <SpringIcon />,
        <KotlinIcon />,
        <JavaIcon />,
        <OracleIcon />,
        <DataScienceIcon />,
    ];

    return (
        <div className="slider">
            <div className="slide-track">
                {technologies.map((icon, index) => (
                    <div key={index} className="slide text-center">
                        {icon}
                    </div>
                ))}
                {technologies.map((icon, index) => (
                    <div key={index} className="slide text-center">
                        {icon}
                    </div>
                ))}
            </div>
        </div>
    );
}
