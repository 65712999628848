import React from "react";
import { useTranslation } from "react-i18next";
import {
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "../icons";
import "./Footer.scss";

export default function PageFooter() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="row justify-content-between">
        <div>
          <div className="footer-header">{t("PHONE")}</div>
          <div>+371 2-655-3255</div>
        </div>
        <div>
          <div className="footer-header">{t("EMAIL")}</div>
          <div>
            <a href="mailto: info@escode.lv">info@escode.lv</a>
          </div>
        </div>
        <div>
          <div className="footer-header">{t("SOCIAL")}</div>
          <div className="footer-icons d-flex flex-row">
            {/*<a className="p-1" href="http://www.google.com">*/}
            {/*  <InstagramIcon />*/}
            {/*</a>*/}
            {/*<a className="p-1" href="http://www.google.com">*/}
            {/*  <FacebookIcon />*/}
            {/*</a>*/}
            {/*<a className="p-1" href="http://www.google.com">*/}
            {/*  <TwitterIcon />*/}
            {/*</a>*/}
            <a className="p-1" href="https://www.linkedin.com/company/escodeit/">
              <LinkedinIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
