import React from "react";

function Icon() {
    return (
        <svg
            height="100"
            viewBox="0 0 94 94"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.7"
                d="M0 93.9999L47.1567 46.8433L94 93.9999H0Z"
                fill="#D3D3D4"
            />
            <path opacity="0.7" d="M0 0H47.1567L0 50.9167V0Z" fill="#222226" />
            <path
                opacity="0.4"
                d="M47.1567 0L0 49.6633V94L47.1567 46.8433L94 0H47.1567Z"
                fill="#222226"
            />
        </svg>
    );
}

export default Icon;
