import React from "react";
import IconProps from "./IconProps";

function Icon(props: IconProps) {
  return (
    <svg
      width="100%"
      viewBox="0 0 478 539"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M374.958 205.917C431.821 205.917 477.917 159.821 477.917 102.958C477.917 46.0961 431.821 0 374.958 0C318.096 0 272 46.0961 272 102.958C272 159.821 318.096 205.917 374.958 205.917Z"
        fill="#E90057"
      />
      <path
        d="M239.543 538.084C189.214 538.084 140.441 520.633 101.536 488.705C62.6303 456.776 35.9994 412.345 26.1805 362.983C16.3615 313.62 23.9621 262.381 47.6872 217.994C71.4123 173.607 109.794 138.82 156.292 119.56C202.791 100.299 254.529 97.7574 302.691 112.367C350.854 126.977 392.46 157.835 420.422 199.682C448.383 241.529 460.97 291.778 456.037 341.865C451.104 391.952 428.956 438.779 393.368 474.367C373.214 494.631 349.242 510.697 322.838 521.634C296.434 532.571 268.122 538.162 239.543 538.084ZM239.543 104.662C120.506 104.662 23.6624 201.505 23.6624 320.542C23.6624 439.58 120.506 536.423 239.543 536.423C358.58 536.423 455.424 439.58 455.424 320.542C455.424 201.505 358.58 104.662 239.543 104.662Z"
        fill="#2F2E41"
      />
      <path
        d="M352.88 389.398L352.856 389.411L245.636 449.114L128.228 390.41L126.205 389.398L132.298 267.53L238.324 191.971L239.494 192.703L349.224 261.436L352.88 389.398Z"
        fill="#021E74"
      />
      <path
        opacity="0.3"
        d="M352.856 389.411L245.637 449.114L128.229 390.41L232.231 317.496L352.856 389.411Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M352.88 389.398L232.23 317.496L239.494 192.703L349.224 261.436L352.88 389.398Z"
        fill="#021E74"
      />
      <path
        d="M130.738 305.135L130.143 304.292L237.352 228.619L350.603 299.624L350.054 300.5L237.385 229.859L130.738 305.135Z"
        fill="white"
      />
      <path
        d="M128.66 346.537L128.08 345.684L234.745 273.266L235.024 273.425L350.698 338.983L350.188 339.881L234.794 274.481L128.66 346.537Z"
        fill="white"
      />
      <path
        d="M169.358 411.277L168.814 410.4L276.315 343.788V216.09H277.347V344.363L169.358 411.277Z"
        fill="white"
      />
      <path
        d="M211.551 432.371L211.003 431.497L314.513 366.573V240.017H315.545V367.145L211.551 432.371Z"
        fill="white"
      />
      <path
        d="M314.123 411.29L193.74 344.403L193.757 344.081L200.095 218.823L201.125 218.875L194.804 343.812L314.625 410.387L314.123 411.29Z"
        fill="white"
      />
      <path
        d="M276.249 432.391L157.218 370.014L157.236 369.683L164.253 244.362L165.284 244.42L158.286 369.409L276.728 431.477L276.249 432.391Z"
        fill="white"
      />
      <path
        d="M41.5155 496.031C64.4439 496.031 83.031 477.444 83.031 454.516C83.031 431.587 64.4439 413 41.5155 413C18.5871 413 0 431.587 0 454.516C0 477.444 18.5871 496.031 41.5155 496.031Z"
        fill="#202025"
      />
    </svg>
  );
}

export default Icon;
