import "./NavBar.scss";
import React from "react";
import NavBarProps from "./NavBarProps";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import BrandLogo from "../icons/BrandLogo";
import LanguageSelector from "../languageselector/LanguageSelector";

function NavBar(props: NavBarProps) {
  /*
  const links = props.navLinks
    .filter((f) => f.linkText)
    .map((page, index) => (
      <NavLink key={index} path={page.path} linkText={page.linkText} />
    ));
  */

  const switches = props.navLinks.map((page, index) => (
    <Route key={index} exact path={page.path}>
      {page.page}
    </Route>
  ));

  return (
    <BrowserRouter>
      <nav className="d-flex justify-content-between">
        <BrandLogo />
        <div>
          {/*<LanguageSelector />*/}
        </div>
      </nav>
      <Switch>{switches}</Switch>
    </BrowserRouter>
  );
}

export default NavBar;
