import React from "react";
import IconProps from "./IconProps";

function Icon(props: IconProps) {
  return (
    <svg
      width="100%"
      viewBox="0 0 339 337"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M338.635 169.317C338.672 209.924 324.102 249.188 297.585 279.941C271.068 310.693 234.373 330.88 194.203 336.816H148.344C148.272 336.879 145.73 337 145.73 337C145.303 336.942 144.872 336.879 144.445 336.816C141.18 336.335 137.945 335.762 134.74 335.096C122.189 332.498 109.978 328.467 98.3478 323.082C98.2266 323.028 98.1098 322.97 97.9885 322.916C91.5072 319.908 85.226 316.486 79.184 312.671C79.0313 312.577 78.8831 312.483 78.7349 312.388C78.4789 312.227 78.2274 312.069 77.9759 311.903C77.895 311.854 77.8142 311.805 77.7333 311.751C72.3316 308.271 67.1329 304.487 62.1624 300.415C61.9199 300.222 61.6774 300.02 61.4349 299.822C60.4603 299.014 59.4947 298.196 58.538 297.37C58.5291 297.365 58.5246 297.356 58.5156 297.352C53.6274 293.116 48.9835 288.605 44.6064 283.842C44.5929 283.824 44.575 283.811 44.5615 283.793C25.3337 262.878 11.6896 237.453 4.89093 209.868C4.83703 209.671 4.79212 209.478 4.74272 209.28C1.58357 196.194 -0.00850022 182.779 3.41293e-05 169.317C3.41293e-05 75.8066 75.8066 0 169.317 0C262.828 0 338.635 75.8066 338.635 169.317Z"
          fill="#021E74"
        />
        <path
          opacity="0.8"
          d="M184.971 194.296L199.779 176.96L214.586 159.625L222.945 149.839C223.408 149.297 222.694 148.453 222.232 148.994L207.424 166.329L192.616 183.665L184.257 193.451C183.795 193.992 184.508 194.837 184.971 194.296Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M189.039 151.088H173.724V336.816H189.039V151.088Z"
          fill="#222226"
        />
        <path
          d="M184.116 219.273L184.085 224.833L183.712 292.317L183.667 300.361L183.568 318.487L183.47 336.816H182.481L182.58 318.487L182.675 301.556L182.724 292.151L183.097 224.846L183.128 219.268L184.116 219.273Z"
          fill="#E90057"
        />
        <path
          d="M191.787 153.558H171.036C170.735 150.307 171.484 147.033 173.414 143.733L174.001 139.724H188.329L189.004 143.682C191.012 146.357 191.79 149.734 191.787 153.558Z"
          fill="#222226"
        />
        <path
          d="M188.772 140.218H173.986C173.837 140.218 173.689 140.184 173.555 140.119C173.421 140.054 173.303 139.959 173.21 139.841C173.118 139.724 173.053 139.587 173.021 139.441C172.989 139.296 172.99 139.144 173.025 138.999L175.515 128.623C175.567 128.408 175.69 128.216 175.865 128.078C176.039 127.941 176.254 127.866 176.476 127.866H186.282C186.504 127.866 186.719 127.941 186.894 128.078C187.068 128.216 187.191 128.408 187.243 128.623L189.733 138.999C189.768 139.144 189.769 139.296 189.737 139.441C189.705 139.587 189.64 139.724 189.548 139.841C189.456 139.959 189.338 140.054 189.203 140.119C189.069 140.184 188.921 140.218 188.772 140.218Z"
          fill="#222226"
        />
        <path
          d="M181.381 122.677C180.005 122.678 178.685 123.226 177.713 124.199C176.74 125.173 176.194 126.492 176.193 127.868V133.056H186.568V127.868C186.568 126.492 186.021 125.173 185.048 124.199C184.076 123.226 182.757 122.678 181.381 122.677Z"
          fill="#222226"
        />
        <path
          d="M175.627 279.4L140.468 325.033L145.33 326.155L172.635 290.996H175.627V279.4Z"
          fill="#222226"
        />
        <path
          d="M188.554 279.4L223.713 325.033L218.851 326.155L191.546 290.996H188.554V279.4Z"
          fill="#222226"
        />
        <path
          d="M291.648 110.146V286.38C280.722 297.796 268.259 307.633 254.618 315.609V110.146H291.648Z"
          fill="#222226"
        />
        <path
          d="M309.227 132.59H284.914V156.903H309.227V132.59Z"
          fill="#222226"
        />
        <path
          d="M275.938 51.4241H270.327V115.011H275.938V51.4241Z"
          fill="#222226"
        />
        <g opacity="0.4">
          <path
            opacity="0.4"
            d="M257.762 110.146H254.618V315.609C255.674 314.991 256.722 314.362 257.762 313.72V110.146Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M273.47 51.4241H270.326V110.146H273.47V51.4241Z"
            fill="white"
          />
        </g>
        <path
          d="M282.109 139.136H261.911V150.357H282.109V139.136Z"
          fill="#E90057"
        />
        <path
          d="M65.99 139.693C82.4085 139.693 95.7183 126.383 95.7183 109.965C95.7183 93.5465 82.4085 80.2367 65.99 80.2367C49.5715 80.2367 36.2617 93.5465 36.2617 109.965C36.2617 126.383 49.5715 139.693 65.99 139.693Z"
          fill="white"
        />
        <path
          d="M63.0657 92.9077C64.9498 92.9077 66.4772 91.3804 66.4772 89.4963C66.4772 87.6122 64.9498 86.0848 63.0657 86.0848C61.1817 86.0848 59.6543 87.6122 59.6543 89.4963C59.6543 91.3804 61.1817 92.9077 63.0657 92.9077Z"
          fill="#D3D3D4"
        />
        <path
          d="M68.9144 127.022C70.7985 127.022 72.3258 125.495 72.3258 123.611C72.3258 121.727 70.7985 120.199 68.9144 120.199C67.0303 120.199 65.5029 121.727 65.5029 123.611C65.5029 125.495 67.0303 127.022 68.9144 127.022Z"
          fill="#D3D3D4"
        />
        <path
          d="M82.0735 107.041C83.4192 107.041 84.5102 105.95 84.5102 104.604C84.5102 103.258 83.4192 102.167 82.0735 102.167C80.7277 102.167 79.6367 103.258 79.6367 104.604C79.6367 105.95 80.7277 107.041 82.0735 107.041Z"
          fill="#D3D3D4"
        />
        <path
          d="M54.2937 120.199C58.6002 120.199 62.0912 116.708 62.0912 112.402C62.0912 108.095 58.6002 104.604 54.2937 104.604C49.9872 104.604 46.4961 108.095 46.4961 112.402C46.4961 116.708 49.9872 120.199 54.2937 120.199Z"
          fill="#D3D3D4"
        />
        <path
          d="M113.184 158.444C114.071 158.444 114.791 157.725 114.791 156.838C114.791 155.951 114.071 155.231 113.184 155.231C112.297 155.231 111.578 155.951 111.578 156.838C111.578 157.725 112.297 158.444 113.184 158.444Z"
          fill="#E90057"
        />
        <path
          d="M127.106 180.138L126.304 180.934L125.508 180.133L124.973 180.663L125.769 181.465L124.968 182.261L125.499 182.796L126.3 182L127.097 182.801L127.631 182.27L126.835 181.469L127.636 180.672L127.106 180.138Z"
          fill="white"
        />
        <path
          d="M133.531 133.556L132.73 134.353L131.933 133.551L131.399 134.082L132.195 134.884L131.394 135.68L131.924 136.214L132.726 135.418L133.522 136.22L134.057 135.689L133.261 134.887L134.062 134.091L133.531 133.556Z"
          fill="white"
        />
        <path
          d="M153.723 45.1615C151.989 46.3172 150.214 43.5666 151.982 42.4622C153.716 41.3066 155.491 44.0572 153.723 45.1615Z"
          fill="white"
        />
        <path
          d="M53.2459 164.704L52.1424 164.462L52.384 163.358L51.6484 163.197L51.4063 164.301L50.3028 164.059L50.1416 164.795L51.2451 165.037L51.0035 166.14L51.7391 166.302L51.9812 165.198L53.0847 165.44L53.2459 164.704Z"
          fill="white"
        />
        <path
          d="M222.114 32.2143L221.011 31.9722L221.252 30.8683L220.517 30.707L220.274 31.811L219.171 31.5693L219.01 32.305L220.113 32.5471L219.872 33.6505L220.607 33.8118L220.849 32.7079L221.953 32.9499L222.114 32.2143Z"
          fill="white"
        />
        <path
          d="M308.345 103.624L307.241 103.382L307.483 102.278L306.747 102.117L306.505 103.221L305.401 102.979L305.24 103.715L306.344 103.957L306.102 105.06L306.838 105.221L307.08 104.118L308.183 104.36L308.345 103.624Z"
          fill="white"
        />
        <path
          d="M99.9104 49.6302L98.8069 49.3881L99.0486 48.2846L98.3129 48.1234L98.0708 49.2269L96.9669 48.9853L96.8057 49.7209L97.9096 49.963L97.668 51.0669L98.4036 51.2277L98.6457 50.1242L99.7492 50.3659L99.9104 49.6302Z"
          fill="white"
        />
        <path
          d="M204.501 86.2877C205.388 86.2877 206.107 85.5686 206.107 84.6815C206.107 83.7943 205.388 83.0752 204.501 83.0752C203.614 83.0752 202.895 83.7943 202.895 84.6815C202.895 85.5686 203.614 86.2877 204.501 86.2877Z"
          fill="#E90057"
        />
        <path
          d="M192.472 109.013L191.369 108.771L191.611 107.667L190.875 107.506L190.633 108.61L189.529 108.369L189.368 109.104L190.472 109.346L190.23 110.45L190.966 110.611L191.208 109.507L192.311 109.749L192.472 109.013Z"
          fill="white"
        />
        <path
          d="M235.095 89.1525L233.992 88.9105L234.233 87.807L233.497 87.6458L233.255 88.7492L232.151 88.5076L231.99 89.2433L233.094 89.4853L232.853 90.5893L233.588 90.7501L233.83 89.6466L234.934 89.8882L235.095 89.1525Z"
          fill="white"
        />
        <path
          opacity="0.8"
          d="M160.718 98.1844L175.526 80.8491L190.333 63.5138L198.693 53.7278C199.155 53.1863 198.441 52.3416 197.979 52.8831L183.171 70.2183L168.363 87.5536L160.004 97.3397C159.542 97.8812 160.256 98.7259 160.718 98.1844Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M175.827 290.565H173.132V336.386H175.827V290.565Z"
          fill="white"
        />
        <path
          d="M182.135 122.43C181.034 122.727 180.062 123.379 179.368 124.284C178.674 125.189 178.297 126.297 178.295 127.437V127.482C178.13 127.531 177.981 127.623 177.862 127.747C177.743 127.872 177.659 128.025 177.617 128.192L175.129 138.571C175.095 138.711 175.093 138.856 175.122 138.997C175.151 139.138 175.211 139.271 175.297 139.386C175.383 139.502 175.494 139.597 175.621 139.664C175.747 139.732 175.888 139.771 176.032 139.779L176.014 139.9L175.946 140.349L174.931 143.493C173.004 146.79 172.838 149.875 173.139 153.127H175.825V279.109L140.776 324.604L143.354 325.197L142.945 325.727L138.081 324.604L173.13 279.109V153.127L170.889 152.925C170.588 149.673 171.208 146.79 173.135 143.493L173.251 140.349L173.319 139.9L173.337 139.779C173.193 139.771 173.053 139.732 172.926 139.664C172.799 139.597 172.689 139.502 172.602 139.386C172.516 139.271 172.456 139.138 172.427 138.997C172.398 138.856 172.401 138.711 172.434 138.571L174.922 128.192C174.964 128.025 175.049 127.872 175.167 127.747C175.286 127.623 175.436 127.531 175.6 127.482V127.437C175.601 126.061 176.147 124.741 177.12 123.768C178.092 122.795 179.412 122.247 180.788 122.245C181.243 122.247 181.696 122.309 182.135 122.43Z"
          fill="white"
        />
        <path
          d="M193.649 290.565H191.142V290.806L218.259 325.725L220.544 325.198L193.649 290.565Z"
          fill="white"
        />
        <path
          d="M231.242 318.487V326.953C219.312 331.641 206.885 334.95 194.203 336.816H138.962C137.547 336.56 136.137 336.286 134.74 335.994V318.487H231.242Z"
          fill="#222226"
        />
        <path
          d="M183.712 292.317C183.398 292.263 183.065 292.21 182.724 292.151C180.716 291.828 178.296 291.46 175.52 291.078C175.332 291.051 175.138 291.024 174.941 290.997C172.65 290.687 170.131 290.373 167.414 290.063C152.516 288.388 131.709 287.045 110.474 288.891C111 286.78 111.485 284.669 111.943 282.567L91.607 285.715L113.551 274.555C115.618 263.089 117.04 251.517 117.809 239.892L106.823 241.589L118.056 235.877C118.859 221.491 118.482 211.916 118.482 211.916C118.482 211.916 81.7399 231.017 52.3586 260.21C51.2807 256.41 50.0996 252.66 48.842 248.977L33.06 262.182L46.071 241.289C41.917 230.404 37.1517 219.762 31.798 209.415L23.2738 216.547L29.9387 205.849C23.1929 193.116 17.9203 185.113 17.9203 185.113C17.9203 185.113 11.9066 194.967 4.74316 210.178C11.499 238.008 25.2161 263.666 44.6069 284.741C48.9903 289.511 53.6417 294.027 58.5385 298.268C59.4951 299.095 60.4607 299.912 61.4353 300.72C66.6262 305.017 72.0687 309 77.7338 312.649C78.0661 312.864 78.3985 313.076 78.7353 313.287C95.8824 324.17 114.856 331.862 134.74 335.994C136.137 336.286 137.547 336.56 138.962 336.816C139.308 336.879 139.654 336.942 139.999 337H145.73C145.73 337 148.272 336.879 148.344 336.816C155.463 330.587 161.916 324.29 167.481 318.487C168.747 317.172 169.965 315.878 171.137 314.621L161.535 309.02L173.858 311.661C174.424 311.037 174.981 310.421 175.52 309.819C178.287 306.73 180.685 303.932 182.675 301.556C183.016 301.143 183.348 300.747 183.667 300.361C187.292 295.987 189.286 293.319 189.286 293.319C189.286 293.319 187.283 292.91 183.712 292.317Z"
          fill="#222226"
        />
        <path
          d="M173.13 153.127H170.444C170.143 149.875 170.893 146.601 172.82 143.304L173.251 140.349L173.318 139.9H176.013L175.946 140.349L175.515 143.304C173.588 146.601 172.838 149.875 173.139 153.127H175.825"
          fill="#E90057"
        />
        <path
          d="M175.826 191.998H173.131V224.784H175.826V191.998Z"
          fill="#E90057"
        />
        <path
          d="M272.39 56.3642C275.118 56.3642 277.33 54.1524 277.33 51.4239C277.33 48.6955 275.118 46.4836 272.39 46.4836C269.661 46.4836 267.449 48.6955 267.449 51.4239C267.449 54.1524 269.661 56.3642 272.39 56.3642Z"
          fill="#E90057"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="184.107"
          y1="171.645"
          x2="223.095"
          y2="171.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="22731.1"
          y1="11145.7"
          x2="26115.7"
          y2="11145.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.3" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="338.635" height="337" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
