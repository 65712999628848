import ArticleProps from "./ArticleProps";
import React, { FunctionComponent } from "react";
import "./Article.scss";

const Article: FunctionComponent<ArticleProps> = (props: ArticleProps) => (
  <div className="justify-content-around align-items-center">
    <div
      className={
        "info-panel float-" + (props.rightVariant ?? false ? "right" : "left")
      }
    >
      <div className="row article-prefix prefix-text">{props.prefixText}</div>
      <div className="row article-header block-header-text">
        {props.headerText}
      </div>
      <div className="row article-body block-body-text">{props.bodyText}</div>
    </div>
    <div
      className={
        "pic-panel text-center float-" +
        (props.rightVariant ?? false ? "left" : "right")
      }
    >
      {props.image}
    </div>
  </div>
);

export default Article;
