import React from "react";
import IconProps from "./IconProps";

function Icon(props: IconProps) {
  return (
    <svg
      width="100%"
      viewBox="60 0 1232 131"
      fill="none"
      overflow="visible"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="64" width="200%" height="5" rx="2.5" fill="#E90057" />
      <path
        d="M344 91C357.255 91 368 80.2548 368 67C368 53.7452 357.255 43 344 43C330.745 43 320 53.7452 320 67C320 80.2548 330.745 91 344 91Z"
        fill="#E90057"
      />
      <circle opacity="0.5" cx="344" cy="67" r="43.5" stroke="#222226" />
      <circle
        cx="344"
        cy="67"
        r="63.5"
        stroke="#222226"
        strokeDasharray="5 5"
      />
      <path
        d="M848 88C861.255 88 872 77.2548 872 64C872 50.7452 861.255 40 848 40C834.745 40 824 50.7452 824 64C824 77.2548 834.745 88 848 88Z"
        fill="#021E74"
      />
      <circle opacity="0.5" cx="848" cy="64" r="43.5" stroke="#222226" />
      <circle
        cx="848"
        cy="64"
        r="63.5"
        stroke="#222226"
        strokeDasharray="5 5"
      />
      <path
        d="M1024 83C1032.84 83 1040 75.8366 1040 67C1040 58.1634 1032.84 51 1024 51C1015.16 51 1008 58.1634 1008 67C1008 75.8366 1015.16 83 1024 83Z"
        fill="#021E74"
      />
      <path
        d="M1176 83C1184.84 83 1192 75.8366 1192 67C1192 58.1634 1184.84 51 1176 51C1167.16 51 1160 58.1634 1160 67C1160 75.8366 1167.16 83 1176 83Z"
        fill="#021E74"
      />
      <path
        d="M672 83C680.837 83 688 75.8366 688 67C688 58.1634 680.837 51 672 51C663.163 51 656 58.1634 656 67C656 75.8366 663.163 83 672 83Z"
        fill="#021E74"
      />
      <path
        d="M520 83C528.837 83 536 75.8366 536 67C536 58.1634 528.837 51 520 51C511.163 51 504 58.1634 504 67C504 75.8366 511.163 83 520 83Z"
        fill="#021E74"
      />
      <path
        d="M168 83C176.837 83 184 75.8366 184 67C184 58.1634 176.837 51 168 51C159.163 51 152 58.1634 152 67C152 75.8366 159.163 83 168 83Z"
        fill="#021E74"
      />
    </svg>
  );
}

export default Icon;
