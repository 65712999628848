import React from "react";

function Icon() {
  return (
    <svg
      height="100"
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M0 0V109H109V0H0ZM57.6558 92.8793C56.0469 96.1485 52.9757 98.2962 49.4171 99.333C43.9488 100.589 38.7185 99.8728 34.8277 97.5376C32.2239 95.9401 30.1904 93.481 28.81 90.6479L37.1114 85.5641C37.1838 85.5946 37.4018 85.9888 37.6931 86.498C38.7517 88.2717 39.6647 89.5274 41.4592 90.409C43.2224 91.0106 47.0932 91.3952 48.5878 88.2926C49.5008 86.7143 49.2104 81.641 49.2104 76.0279C49.2096 67.2077 49.2506 58.424 49.2506 49.704H59.4608C59.4608 59.296 59.5131 68.3805 59.4608 77.7405C59.4826 83.4783 59.9805 88.5935 57.6558 92.8793ZM100.012 89.9948C96.4633 102.135 76.6758 102.529 68.7685 94.5082C67.0978 92.6203 66.0505 91.6341 65.0538 89.455C69.256 87.0378 69.256 87.0378 73.3448 84.6721C75.5658 88.0859 77.6193 89.9634 81.314 90.7316C86.3263 91.3437 91.369 89.6215 90.238 84.298C89.0757 79.9502 79.9746 78.8925 73.7808 74.2429C67.4928 70.0199 66.0191 59.7582 71.1866 53.8948C72.9088 51.7262 75.8457 50.1077 78.9273 49.3299L82.1433 48.914C88.3179 48.7893 92.1774 50.4199 95.0105 53.5835C95.7988 54.3823 96.4423 55.2438 97.6457 57.1108C94.3565 59.2071 94.367 59.187 89.6556 62.2373C88.6493 60.0695 86.9794 58.7091 85.2145 58.1179C82.4746 57.2878 79.0198 58.1903 78.303 61.0853C78.0545 61.9774 78.1059 62.8075 78.5009 64.2812C79.611 66.8126 83.3362 67.9131 86.6777 69.4487C96.2967 73.35 99.5449 77.533 100.344 82.5121C101.113 86.7989 100.158 89.5797 100.012 89.9948Z"
        fill="#222226"
      />
    </svg>
  );
}

export default Icon;
