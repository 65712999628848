import React from "react";
import { CubeIcon } from "../icons";
import { useTranslation, Trans } from "react-i18next";
import "./MainBlock.scss";

function MainBlock() {
  const { t } = useTranslation();

  return (
    <>
      <div className="mainblock-info row align-items-center justify-content-between">
        <div>
          <div className="prefix-text">{t("COMPANYSLOGAN")}</div>
          <div className="big-text">
            <Trans i18nKey="COMPANYDESCRIPTION">
              We are
              <span className="text-blue">working on code refactoring</span>
              and
              <span className="text-blue">performance optimization.</span>
              So that our clients can
              <span className="text-pink">expect excellent results.</span>
            </Trans>
          </div>
        </div>
      </div>
      <div className="mainblock-pic">
        <CubeIcon />
      </div>
    </>
  );
}

export default MainBlock;
