import React from "react";
import IconProps from "./IconProps";

function Icon(props: IconProps) {
    return (
        <svg
            width="40"
            height="39"
            viewBox="0 0 40 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 38H27"
                stroke="#021E74"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 33V38"
                stroke="#021E74"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 28H38"
                stroke="#021E74"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.9333 3H36.4667C37.1385 3 37.7829 3.26339 38.258 3.73223C38.7331 4.20107 39 4.83696 39 5.5V30.5C39 31.163 38.7331 31.7989 38.258 32.2678C37.7829 32.7366 37.1385 33 36.4667 33H3.53333C2.86145 33 2.21709 32.7366 1.742 32.2678C1.2669 31.7989 1 31.163 1 30.5V5.5C1 4.83696 1.2669 4.20107 1.742 3.73223C2.21709 3.26339 2.86145 3 3.53333 3H6.06667"
                stroke="#021E74"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 12.0017C17 12.7974 17.3161 13.5604 17.8787 14.123C18.4413 14.6856 19.2044 15.0017 20 15.0017C20.7956 15.0017 21.5587 14.6856 22.1213 14.123C22.6839 13.5604 23 12.7974 23 12.0017C23 11.2061 22.6839 10.443 22.1213 9.88039C21.5587 9.31778 20.7956 9.00171 20 9.00171C19.2044 9.00171 18.4413 9.31778 17.8787 9.88039C17.3161 10.443 17 11.2061 17 12.0017V12.0017Z"
                stroke="#021E74"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.1246 2.54933L22.8616 4.91577C22.9829 5.30904 23.2527 5.64251 23.6162 5.84861C23.9798 6.0547 24.4099 6.11799 24.8194 6.02565L27.2742 5.46989C27.7397 5.36692 28.2268 5.41247 28.664 5.59986C29.1011 5.78726 29.4653 6.10663 29.7032 6.51113C29.941 6.91564 30.0399 7.38397 29.9854 7.84745C29.9309 8.31092 29.7259 8.74512 29.4004 9.08637L27.6811 10.8938C27.3941 11.1953 27.2346 11.5919 27.2346 12.0037C27.2346 12.4155 27.3941 12.812 27.6811 13.1136L29.4004 14.9242C29.7251 15.2656 29.9295 15.6996 29.9836 16.1627C30.0377 16.6258 29.9387 17.0937 29.701 17.4978C29.4633 17.9019 29.0994 18.221 28.6627 18.4084C28.226 18.5958 27.7394 18.6416 27.2742 18.5391L24.8194 17.9833C24.4099 17.891 23.9798 17.9543 23.6162 18.1604C23.2527 18.3665 22.9829 18.6999 22.8616 19.0932L22.1246 21.4548C21.9876 21.9014 21.7069 22.2929 21.3242 22.5713C20.9414 22.8497 20.4771 23 20 23C19.5229 23 19.0586 22.8497 18.6758 22.5713C18.2931 22.2929 18.0124 21.9014 17.8754 21.4548L17.1384 19.0883C17.0171 18.6951 16.7473 18.3616 16.3838 18.1555C16.0202 17.9494 15.5901 17.8861 15.1806 17.9784L12.7258 18.5342C12.2606 18.6367 11.774 18.5909 11.3373 18.4035C10.9006 18.2161 10.5367 17.897 10.299 17.4929C10.0613 17.0888 9.96229 16.6209 10.0164 16.1578C10.0705 15.6947 10.2749 15.2607 10.5996 14.9193L12.3189 13.1087C12.6059 12.8071 12.7654 12.4106 12.7654 11.9988C12.7654 11.587 12.6059 11.1905 12.3189 10.8889L10.5996 9.07822C10.2741 8.73698 10.0691 8.30277 10.0146 7.8393C9.96011 7.37583 10.059 6.90749 10.2968 6.50298C10.5347 6.09848 10.8989 5.77911 11.336 5.59171C11.7732 5.40432 12.2603 5.35877 12.7258 5.46175L15.1806 6.0175C15.5901 6.10984 16.0202 6.04656 16.3838 5.84046C16.7473 5.63437 17.0171 5.30089 17.1384 4.90762L17.8754 2.54118C18.0133 2.09482 18.2948 1.70381 18.6781 1.42617C19.0614 1.14854 19.526 0.999089 20.0031 1C20.4802 1.00092 20.9442 1.15215 21.3264 1.43125C21.7086 1.71035 21.9885 2.10245 22.1246 2.54933V2.54933Z"
                stroke="#E90057"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Icon;
