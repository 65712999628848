import React from "react";
import IconProps from "./IconProps";

function Icon(props: IconProps) {
  return (
    <svg
      width="100%"
      viewBox="0 0 424 367"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M358.639 194.752C329.414 172.931 283.641 152.293 233.055 138.132C186.998 125.239 142.074 119.014 106.551 120.603L106.366 116.477C142.313 114.869 187.702 121.147 234.172 134.156C285.228 148.449 331.498 169.329 361.115 191.444L358.639 194.752Z"
        fill="#D3D3D4"
      />
      <path
        d="M355.606 211.56C355.632 230.893 351.574 250.015 343.696 267.675C343.039 269.154 342.354 270.618 341.641 272.069C327.941 300.097 305.136 322.66 276.947 336.08C248.758 349.5 216.848 352.984 186.422 345.964C155.995 338.944 128.848 321.834 109.409 297.426C89.9689 273.017 79.3834 242.749 79.3789 211.56C79.3789 208.367 79.4878 205.199 79.7055 202.056C79.8089 200.458 79.9427 198.869 80.1067 197.29C83.7694 162.131 100.806 129.718 127.7 106.739C154.594 83.7603 189.294 71.9697 224.638 73.8004C259.983 75.631 293.276 90.9433 317.646 116.577C342.016 142.211 355.604 176.211 355.606 211.56V211.56Z"
        fill="#021E74"
      />
      <path
        opacity="0.1"
        d="M343.696 267.675C343.038 269.154 342.353 270.619 341.641 272.07C337.605 272.508 333.357 272.826 328.896 273.024C325.204 273.189 321.403 273.272 317.521 273.272C283.604 273.272 242.786 267.019 201.088 255.347C154.617 242.337 112.575 224.14 82.7031 204.105C81.6858 203.424 80.6865 202.741 79.7051 202.057C79.8085 200.458 79.9422 198.87 80.1063 197.291C81.69 198.418 83.3234 199.547 85.0064 200.677C114.527 220.473 156.147 238.476 202.204 251.37C243.539 262.942 283.972 269.142 317.5 269.142C321.333 269.142 325.07 269.06 328.71 268.898C334.012 268.658 339.007 268.251 343.696 267.675Z"
        fill="black"
      />
      <path
        d="M317.521 269.968C283.606 269.969 242.786 263.715 201.089 252.042C154.619 239.033 112.575 220.836 82.7026 200.801C52.2067 180.349 37.9065 160.196 42.4361 144.054C46.9656 127.913 69.6698 118.119 106.366 116.477L106.551 120.603C89.4144 121.37 75.4487 123.914 65.042 128.164C54.6606 132.404 48.3944 138.125 46.4178 145.169C44.4411 152.213 46.8172 160.354 53.4798 169.365C60.1588 178.398 70.7664 187.821 85.0077 197.372C114.525 217.169 156.147 235.172 202.205 248.066C243.541 259.637 283.971 265.838 317.501 265.839C321.332 265.839 325.068 265.757 328.709 265.595C345.846 264.828 359.812 262.284 370.219 258.033C380.6 253.794 386.866 248.073 388.843 241.028C392.392 228.38 381.666 211.945 358.639 194.752L361.115 191.444C372.965 200.292 381.736 209.013 387.186 217.365C393.029 226.319 394.926 234.656 392.825 242.143C388.295 258.284 365.591 268.078 328.895 269.721C325.202 269.886 321.404 269.968 317.521 269.968Z"
        fill="#D3D3D4"
      />
      <path
        d="M221.145 48.7368C222.019 48.7368 222.728 48.0291 222.728 47.1561C222.728 46.2831 222.019 45.5754 221.145 45.5754C220.271 45.5754 219.562 46.2831 219.562 47.1561C219.562 48.0291 220.271 48.7368 221.145 48.7368Z"
        fill="black"
      />
      <path
        d="M11.1817 117.329C12.6854 117.329 13.9044 116.112 13.9044 114.61C13.9044 113.108 12.6854 111.891 11.1817 111.891C9.67798 111.891 8.45898 113.108 8.45898 114.61C8.45898 116.112 9.67798 117.329 11.1817 117.329Z"
        fill="#FFE978"
      />
      <path
        d="M5.37863 226.416C8.34917 226.416 10.7573 224.011 10.7573 221.044C10.7573 218.077 8.34917 215.672 5.37863 215.672C2.4081 215.672 0 218.077 0 221.044C0 224.011 2.4081 226.416 5.37863 226.416Z"
        fill="#FFE978"
      />
      <path
        d="M328.238 60.2207C330.218 60.2207 331.823 58.6177 331.823 56.6403C331.823 54.663 330.218 53.06 328.238 53.06C326.258 53.06 324.653 54.663 324.653 56.6403C324.653 58.6177 326.258 60.2207 328.238 60.2207Z"
        fill="#FFE978"
      />
      <path
        d="M409.866 114.886C411.245 114.886 412.363 113.77 412.363 112.392C412.363 111.015 411.245 109.898 409.866 109.898C408.487 109.898 407.369 111.015 407.369 112.392C407.369 113.77 408.487 114.886 409.866 114.886Z"
        fill="#FFE978"
      />
      <path
        d="M106.668 84.2421C108.82 84.2421 110.564 82.5001 110.564 80.3513C110.564 78.2024 108.82 76.4604 106.668 76.4604C104.517 76.4604 102.772 78.2024 102.772 80.3513C102.772 82.5001 104.517 84.2421 106.668 84.2421Z"
        fill="#FFE978"
      />
      <path
        d="M401.126 195.944C402.505 195.944 403.623 194.828 403.623 193.45C403.623 192.073 402.505 190.956 401.126 190.956C399.747 190.956 398.629 192.073 398.629 193.45C398.629 194.828 399.747 195.944 401.126 195.944Z"
        fill="#FFE978"
      />
      <path
        d="M21.0387 299.024C22.7552 299.024 24.1467 297.634 24.1467 295.92C24.1467 294.205 22.7552 292.816 21.0387 292.816C19.3222 292.816 17.9307 294.205 17.9307 295.92C17.9307 297.634 19.3222 299.024 21.0387 299.024Z"
        fill="#FFE978"
      />
      <path
        d="M80.8179 343.486C82.6768 343.486 84.1837 341.981 84.1837 340.124C84.1837 338.268 82.6768 336.763 80.8179 336.763C78.9591 336.763 77.4521 338.268 77.4521 340.124C77.4521 341.981 78.9591 343.486 80.8179 343.486Z"
        fill="#FFE978"
      />
      <path
        d="M176.663 6.63394H170.021V0H166.458V6.63394H159.812V10.196H166.458V16.8299H170.021V10.196H176.663V6.63394Z"
        fill="#FFE978"
      />
      <path
        d="M332.737 360.6H328.564V356.435H326.327V360.6H322.157V362.835H326.327V367H328.564V362.835H332.737V360.6Z"
        fill="#FFE978"
      />
      <path
        d="M424 327.298H420.883V324.185H419.208V327.298H416.091V328.971H419.208V332.085H420.883V328.971H424V327.298Z"
        fill="#FFE978"
      />
      <path
        opacity="0.1"
        d="M219.563 115.137C225.973 115.137 231.169 109.947 231.169 103.545C231.169 97.143 225.973 91.9532 219.563 91.9532C213.153 91.9532 207.957 97.143 207.957 103.545C207.957 109.947 213.153 115.137 219.563 115.137Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M130.408 199.968C136.818 199.968 142.014 194.778 142.014 188.376C142.014 181.974 136.818 176.784 130.408 176.784C123.998 176.784 118.802 181.974 118.802 188.376C118.802 194.778 123.998 199.968 130.408 199.968Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M258.602 316.414C265.012 316.414 270.208 311.224 270.208 304.822C270.208 298.42 265.012 293.23 258.602 293.23C252.192 293.23 246.996 298.42 246.996 304.822C246.996 311.224 252.192 316.414 258.602 316.414Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M219.563 217.883C225.973 217.883 231.169 212.693 231.169 206.291C231.169 199.889 225.973 194.699 219.563 194.699C213.153 194.699 207.957 199.889 207.957 206.291C207.957 212.693 213.153 217.883 219.563 217.883Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M136.211 281.638C142.62 281.638 147.817 276.448 147.817 270.046C147.817 263.644 142.62 258.454 136.211 258.454C129.801 258.454 124.604 263.644 124.604 270.046C124.604 276.448 129.801 281.638 136.211 281.638Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M281.814 155.708C288.224 155.708 293.42 150.518 293.42 144.116C293.42 137.714 288.224 132.524 281.814 132.524C275.404 132.524 270.208 137.714 270.208 144.116C270.208 150.518 275.404 155.708 281.814 155.708Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M320.325 234.217C326.735 234.217 331.931 229.027 331.931 222.625C331.931 216.223 326.735 211.033 320.325 211.033C313.915 211.033 308.719 216.223 308.719 222.625C308.719 229.027 313.915 234.217 320.325 234.217Z"
        fill="black"
      />
      <path
        d="M219.564 170.988C228.013 170.988 234.862 164.147 234.862 155.708C234.862 147.269 228.013 140.428 219.564 140.428C211.114 140.428 204.265 147.269 204.265 155.708C204.265 164.147 211.114 170.988 219.564 170.988Z"
        fill="white"
      />
      <path
        d="M219.563 133.578C213.827 133.578 208.325 135.854 204.269 139.905C200.212 143.957 197.934 149.452 197.934 155.181C197.934 167.112 219.563 206.291 219.563 206.291C219.563 206.291 241.192 167.112 241.192 155.181C241.192 149.452 238.914 143.957 234.857 139.905C230.801 135.854 225.299 133.578 219.563 133.578ZM219.563 165.719C217.581 165.719 215.643 165.132 213.994 164.032C212.346 162.932 211.061 161.368 210.303 159.539C209.544 157.71 209.345 155.697 209.732 153.755C210.119 151.813 211.074 150.029 212.475 148.629C213.877 147.229 215.663 146.275 217.608 145.889C219.552 145.503 221.567 145.701 223.399 146.459C225.23 147.217 226.796 148.5 227.897 150.146C228.999 151.792 229.586 153.728 229.586 155.708C229.586 157.023 229.327 158.325 228.823 159.539C228.32 160.754 227.581 161.857 226.651 162.787C225.72 163.717 224.615 164.454 223.399 164.957C222.183 165.46 220.879 165.719 219.563 165.719Z"
        fill="#222226"
      />
      <path
        d="M136.211 236.324C144.66 236.324 151.51 229.483 151.51 221.044C151.51 212.605 144.66 205.764 136.211 205.764C127.762 205.764 120.912 212.605 120.912 221.044C120.912 229.483 127.762 236.324 136.211 236.324Z"
        fill="white"
      />
      <path
        d="M136.211 198.914C130.474 198.914 124.973 201.19 120.916 205.241C116.86 209.293 114.581 214.788 114.581 220.517C114.581 232.448 136.211 271.627 136.211 271.627C136.211 271.627 157.84 232.448 157.84 220.517C157.84 217.68 157.28 214.871 156.193 212.25C155.107 209.629 153.513 207.247 151.505 205.241C149.496 203.235 147.112 201.644 144.488 200.558C141.864 199.473 139.051 198.914 136.211 198.914ZM136.211 231.055C134.228 231.055 132.29 230.468 130.642 229.368C128.993 228.268 127.709 226.704 126.95 224.875C126.191 223.046 125.993 221.033 126.38 219.091C126.766 217.149 127.721 215.365 129.123 213.965C130.525 212.565 132.311 211.611 134.255 211.225C136.199 210.839 138.215 211.037 140.046 211.795C141.878 212.553 143.443 213.836 144.545 215.482C145.646 217.128 146.234 219.064 146.234 221.044C146.234 222.359 145.975 223.66 145.471 224.875C144.967 226.09 144.229 227.193 143.298 228.123C142.367 229.053 141.262 229.79 140.046 230.293C138.83 230.796 137.527 231.055 136.211 231.055Z"
        fill="#222226"
      />
      <path
        d="M320.324 188.903C328.774 188.903 335.623 182.062 335.623 173.623C335.623 165.184 328.774 158.342 320.324 158.342C311.875 158.342 305.025 165.184 305.025 173.623C305.025 182.062 311.875 188.903 320.324 188.903Z"
        fill="white"
      />
      <path
        d="M320.325 151.493C317.484 151.493 314.672 152.052 312.047 153.137C309.423 154.223 307.039 155.814 305.03 157.82C303.022 159.826 301.429 162.208 300.342 164.829C299.255 167.45 298.695 170.259 298.695 173.096C298.695 185.027 320.325 224.206 320.325 224.206C320.325 224.206 341.954 185.027 341.954 173.096C341.954 170.259 341.395 167.45 340.308 164.829C339.221 162.208 337.628 159.826 335.619 157.82C333.611 155.814 331.226 154.223 328.602 153.137C325.978 152.052 323.165 151.493 320.325 151.493ZM320.325 183.634C318.342 183.634 316.404 183.047 314.756 181.947C313.108 180.847 311.823 179.283 311.064 177.454C310.306 175.625 310.107 173.612 310.494 171.67C310.881 169.728 311.835 167.944 313.237 166.544C314.639 165.144 316.425 164.19 318.369 163.804C320.314 163.418 322.329 163.616 324.161 164.374C325.992 165.132 327.558 166.415 328.659 168.061C329.76 169.707 330.348 171.643 330.348 173.623C330.348 174.938 330.089 176.239 329.585 177.454C329.082 178.669 328.343 179.772 327.412 180.702C326.482 181.632 325.377 182.369 324.161 182.872C322.944 183.375 321.641 183.634 320.325 183.634Z"
        fill="#222226"
      />
      <path
        d="M219.564 68.2422C228.013 68.2422 234.862 61.401 234.862 52.962C234.862 44.523 228.013 37.6818 219.564 37.6818C211.114 37.6818 204.265 44.523 204.265 52.962C204.265 61.401 211.114 68.2422 219.564 68.2422Z"
        fill="white"
      />
      <path
        d="M219.563 30.8322C213.827 30.8322 208.325 33.1082 204.269 37.1595C200.212 41.2109 197.934 46.7057 197.934 52.4351C197.934 64.3662 219.563 103.545 219.563 103.545C219.563 103.545 241.192 64.3662 241.192 52.4351C241.192 46.7057 238.914 41.2109 234.857 37.1595C230.801 33.1082 225.299 30.8322 219.563 30.8322ZM219.563 62.9732C217.581 62.9732 215.643 62.3861 213.994 61.286C212.346 60.186 211.061 58.6225 210.303 56.7931C209.544 54.9638 209.345 52.9509 209.732 51.009C210.119 49.067 211.074 47.2832 212.475 45.8831C213.877 44.483 215.663 43.5295 217.608 43.1432C219.552 42.757 221.567 42.9552 223.399 43.7129C225.23 44.4707 226.796 45.7538 227.897 47.4001C228.999 49.0465 229.586 50.982 229.586 52.9621C229.586 54.2767 229.327 55.5786 228.823 56.7932C228.32 58.0078 227.581 59.1114 226.651 60.041C225.72 60.9707 224.615 61.7081 223.399 62.2112C222.183 62.7143 220.879 62.9732 219.563 62.9732Z"
        fill="#222226"
      />
      <path
        d="M258.602 271.1C267.051 271.1 273.901 264.259 273.901 255.82C273.901 247.381 267.051 240.539 258.602 240.539C250.152 240.539 243.303 247.381 243.303 255.82C243.303 264.259 250.152 271.1 258.602 271.1Z"
        fill="white"
      />
      <path
        d="M258.602 233.69C252.866 233.69 247.364 235.966 243.308 240.017C239.251 244.069 236.973 249.563 236.973 255.293C236.973 267.224 258.602 306.403 258.602 306.403C258.602 306.403 280.232 267.224 280.232 255.293C280.232 252.456 279.672 249.647 278.585 247.026C277.498 244.405 275.905 242.023 273.896 240.017C271.888 238.011 269.503 236.42 266.879 235.334C264.255 234.249 261.442 233.69 258.602 233.69ZM258.602 265.831C256.62 265.831 254.682 265.244 253.033 264.144C251.385 263.044 250.1 261.48 249.342 259.651C248.583 257.822 248.385 255.809 248.771 253.867C249.158 251.925 250.113 250.141 251.514 248.741C252.916 247.341 254.702 246.387 256.647 246.001C258.591 245.615 260.606 245.813 262.438 246.571C264.269 247.329 265.835 248.612 266.936 250.258C268.038 251.904 268.626 253.84 268.626 255.82C268.626 257.135 268.366 258.436 267.863 259.651C267.359 260.866 266.621 261.969 265.69 262.899C264.759 263.829 263.654 264.566 262.438 265.069C261.222 265.572 259.918 265.831 258.602 265.831Z"
        fill="#E90057"
      />
      <path
        d="M130.407 153.6C138.857 153.6 145.706 146.759 145.706 138.32C145.706 129.881 138.857 123.04 130.407 123.04C121.958 123.04 115.108 129.881 115.108 138.32C115.108 146.759 121.958 153.6 130.407 153.6Z"
        fill="white"
      />
      <path
        d="M130.408 116.191C124.671 116.191 119.17 118.467 115.113 122.518C111.057 126.569 108.778 132.064 108.778 137.794C108.778 149.725 130.408 188.903 130.408 188.903C130.408 188.903 152.037 149.725 152.037 137.794C152.037 132.064 149.758 126.569 145.702 122.518C141.646 118.467 136.144 116.191 130.408 116.191ZM130.408 148.332C128.425 148.332 126.487 147.744 124.839 146.644C123.191 145.544 121.906 143.981 121.147 142.152C120.389 140.322 120.19 138.309 120.577 136.367C120.964 134.425 121.918 132.642 123.32 131.242C124.722 129.841 126.508 128.888 128.452 128.502C130.397 128.115 132.412 128.314 134.244 129.071C136.075 129.829 137.641 131.112 138.742 132.759C139.843 134.405 140.431 136.34 140.431 138.32C140.431 139.635 140.172 140.937 139.668 142.152C139.164 143.366 138.426 144.47 137.495 145.399C136.565 146.329 135.46 147.066 134.244 147.57C133.027 148.073 131.724 148.332 130.408 148.332Z"
        fill="#E90057"
      />
      <path
        d="M281.814 110.922C290.263 110.922 297.112 104.08 297.112 95.6413C297.112 87.2023 290.263 80.3611 281.814 80.3611C273.364 80.3611 266.515 87.2023 266.515 95.6413C266.515 104.08 273.364 110.922 281.814 110.922Z"
        fill="white"
      />
      <path
        d="M281.814 73.5112C276.077 73.5112 270.576 75.7873 266.52 79.8386C262.463 83.8899 260.185 89.3847 260.185 95.1142C260.185 107.045 281.814 146.224 281.814 146.224C281.814 146.224 303.443 107.045 303.443 95.1142C303.443 89.3847 301.165 83.8899 297.108 79.8386C293.052 75.7872 287.55 73.5112 281.814 73.5112ZM281.814 105.652C279.832 105.652 277.894 105.065 276.245 103.965C274.597 102.865 273.312 101.302 272.554 99.4722C271.795 97.6429 271.596 95.63 271.983 93.6881C272.37 91.7461 273.325 89.9622 274.726 88.5622C276.128 87.1621 277.914 86.2086 279.859 85.8223C281.803 85.436 283.818 85.6343 285.65 86.392C287.481 87.1497 289.047 88.4329 290.148 90.0792C291.25 91.7256 291.837 93.6611 291.837 95.6411C291.837 98.2963 290.781 100.843 288.902 102.72C287.022 104.598 284.472 105.652 281.814 105.652V105.652Z"
        fill="#E90057"
      />
    </svg>
  );
}

export default Icon;
