import React from "react";
import IconProps from "./IconProps";

function Icon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 34C8 34.7956 8.31607 35.5587 8.87868 36.1213C9.44129 36.6839 10.2044 37 11 37C11.7956 37 12.5587 36.6839 13.1213 36.1213C13.6839 35.5587 14 34.7956 14 34C14 33.2044 13.6839 32.4413 13.1213 31.8787C12.5587 31.3161 11.7956 31 11 31C10.2044 31 9.44129 31.3161 8.87868 31.8787C8.31607 32.4413 8 33.2044 8 34V34Z"
        stroke="#021E74"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 34C21 34.7956 21.3161 35.5587 21.8787 36.1213C22.4413 36.6839 23.2044 37 24 37C24.7956 37 25.5587 36.6839 26.1213 36.1213C26.6839 35.5587 27 34.7956 27 34C27 33.2044 26.6839 32.4413 26.1213 31.8787C25.5587 31.3161 24.7956 31 24 31C23.2044 31 22.4413 31.3161 21.8787 31.8787C21.3161 32.4413 21 33.2044 21 34V34Z"
        stroke="#021E74"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 11.5393L5.82991 24.2117C5.97015 24.7248 6.27994 25.1783 6.71114 25.5017C7.14233 25.8251 7.67078 26.0003 8.21436 26H26.004C26.5484 26.0013 27.078 25.8267 27.5102 25.5032C27.9423 25.1797 28.2529 24.7256 28.3933 24.2117L33.718 4.68327C33.9279 3.91355 34.3922 3.23316 35.0388 2.7479C35.6853 2.26264 36.4778 1.99971 37.2931 2H39"
        stroke="#E90057"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18H24"
        stroke="#021E74"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
